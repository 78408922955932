html,
body {
  font-family: "Muli", sans-serif !important;
  font-size: 18px !important;
  background: #ffffff !important;
  color: #333333;
}

p {
  line-height: 1.5 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Muli", sans-serif !important;
  font-weight: 700;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.font-muli,
.ant-card,
.ant-select,
.ant-input {
  font-family: "Muli", sans-serif !important;
}

.bold {
  font-weight: bold;
}

.slide-pane__overlay {
  z-index: 100 !important;
}

.am-modal-content {
  border-radius: 10px 10px 0px 0px;
}

.am-modal-p {
  border-bottom: solid 1px #d8d8d8 !important;
}

.popup-button-gosure {
  color: #fff !important;
}

.ant-modal-mask {
  z-index: 2 !important;
}

.ant-modal-wrap {
  z-index: 2 !important;
}

.am-navbar {
  background: #ffd500 !important;
}

.bg-dark {
  background: #eeeeee !important;
}

.ant-card {
  font-size: 14px;
}

.ant-input {
  font-size: 14px;
}

.coupon {
  border: 1px dashed #e2e1e1;
  color: #ffd500;
  padding: 5px;
  background: #f6f6f6;
}

.coupon-container {
  margin-bottom: 25px;
}

label {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}

.ant-radio-wrapper {
  font-size: 14px;
}

.linear-wipe {
  margin-top: 120px;
  font-size: 28px;
  color: #000;
  background: rgb(255, 214, 0);
  padding: 28px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}

.container {
  width: 1200px;
  background: #ffd500;
  margin-left: auto;
  margin-right: auto;
}

a {
  color: #151515 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-80 {
  margin-top: 80px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-100 {
  margin-left: 100px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-5 {
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-16 {
  margin-top: 16px;
}

.margin-auto {
  margin: auto;
}

.pl-30 {
  padding-left: 30px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px !important;
}

.text-primary {
  color: #fcbe00;
}

.text-light {
  color: #7f7f7f;
}

.text-title {
  color: #151515;
}

.text-content {
  color: #333333;
}

.text-danger {
  color: #f5222d;
}

.inline-block {
  display: inline-block;
}

.v-center {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.dcontents {
  display: contents;
}

.dflex {
  display: flex;
}

.terms-content-ul-disc {
  list-style-type: disc;
  padding-left: 25px;
}

.terms-content-ul-disc > li {
  margin: 10px 0px;
}

.terms-content-crackscreen-modal .ant-modal-header {
  /* background-image: linear-gradient(to left,#0180c8,#2b2171,#2b2171); */
  padding: 0px;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.ant-layout {
  background-color: #ffffff !important;
}

.button-primary {
  border-radius: 3px !important;
  background-color: #ffd500 !important;
  color: #151515 !important;
  border-color: #ffd500 !important;
  font-size: 12px !important;
  padding: 16px !important;
  height: auto !important;
  font-weight: 800;
}

.button-primary:hover {
  background-color: #fcbe00 !important;
}

.button-primary-basic {
  border: none !important;
  color: #fcbe00 !important;
  font-size: 12px !important;
}

.button-primary-basic.transperent {
  background: transparent;
}

.button-white {
  border: none !important;
  font-size: 12px !important;
  background: #ffffff;
  padding: 16px 30px;
  font-weight: 800;
  color: #fcbe00;
  opacity: 0.7;
  border-radius: 3px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}

.button-white-mobile {
    min-height: 75px;
    border: none !important;
    font-size: 14px !important;
    background: #ffffff;
    font-weight: 800;
    color: #000000;
    opacity: 0.9;
    border-radius: 3px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 5px;
}

.hero {
  background: url("../image/home_header.png");
  background-size: cover !important;
  background-position: center;
  width: 100vw;
  min-height: calc(100vh - 50px);
  height: 100%;
  display: flex;
}


.vehicle-hero {
  background: url("https://storage.googleapis.com/pp_img/website/vehicle-bg.jpg");
  background-size: cover;
  background-position: right bottom;
  width: 100vw;
  min-height: calc(100vh - 54px);
  height: 100%;
  display: flex;
  padding: 20px 0px;
  background-repeat: no-repeat;
}

.career-hero {
  width: 100vw;
  min-height: calc(100vh - 50px);
  height: 100%;
  display: flex;
  padding: 20px;
}

.hero .center-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: auto;
  padding: 0 100px;
}

.slider-section .slide-wrapper {
  padding-left: 10px;
  overflow: hidden;
}

.slider-section .slide {
  margin: 15px 20px;
}

.slider-section .slide img {
  float: left;
}

.slider-section .slide .slide-title {
  color: #151515;
  font-size: 18px;
}

.slider-section .slide .slide-content {
  color: #333333;
  font-size: 13px;
  margin-bottom: 5px;
}

.slider-section .slide .link {
  display: inline-block;
  /* padding: 0 5px 0px 5px; */
  border-bottom: 3px solid #ffd500;
}

.slide .link a {
  font-size: 12px !important;
}

.ant-card-bordered {
  height: 100%;
}

.ant-card-body {
  padding: 24px !important;
}

.card {
  background: rgba(255, 255, 255, 0.85);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}

.card.opacity-1 {
  background: rgba(255, 255, 255, 1) !important;
}

/* .card.opacity {
    opacity: 0.7;
    position: relative;
}

.card.opacity::before {
    z-index: -1;
    content: '';
    position: absolute;
    opacity: 0.7;
} */

.card-select {
  color: #bbbbbb;
  outline: none !important;
}

.border {
  border: 1px solid #d9d9d9 !important;
}

.grey-border {
  border: 1px solid grey !important;
}

.card-select .ant-select-selection {
  background-color: transparent !important;
}

.card-select .ant-select-arrow {
  color: #7f7f7f;
  font-weight: 700;
}

.ant-select-selection__rendered {
  margin-left: 0px;
}

@media (min-width: 1200px) {
  .card-select.no-border .ant-select-selection,
  .ant-calendar-picker.no-border .ant-calendar-picker-input,
  .ant-input.no-border,
  .card-date-input-container .no-border input {
    border: none !important;
  }

  .ant-select-selection-selected-value,
  .ant-select-selection__placeholder,
  .card-date-input-container input {
  }

  .cph-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .cp-16 {
    padding: 16px;
  }

  .bl {
    border-left: 1px solid #e5e5e5;
  }

  .br {
    border-right: 1px solid #e5e5e5;
  }

  .card-select,
  .card-date-picker,
  .card-date-input-container input {
    font-size: 20px;
    font-weight: 300;
    padding-left: 0px !important;
  }

  .health-date-picker .ant-calendar-picker-input {
    font-size: 20px !important;
    font-weight: 300 !important;
    color: #000000 !important;
    padding-left: 0px;
  }

  .img.mastercard {
    /* marginTop: '10px',
        alignItems: 'center',
        maxWidth: '70px',
        height:'40px',
        width: '100%',
        display: 'block',
        paddingRight: '16px' */
    margin-top: 10px;
    align-items: center;
    height: 40px;
    padding-right: 10px;
  }
}

.ant-calendar-picker-input {
  /* border: none !important; */
  background: inherit !important;
}

.divider {
  height: 1px;
  background-color: #e5e5e5;
}

.divider-vertical {
  height: 100%;
  content: "";
  width: 1px;
  background-color: #e5e5e5;
}

.pr-0 {
  padding-right: 0px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.pv-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pv-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.ph-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.ph-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

label {
  color: #151515;
  display: block;
}

.full-width {
  width: 100%;
}

.vmiddle {
  vertical-align: middle;
}

.f15-b800 {
  font-weight: 800;
  font-size: 15px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #bbbbbb !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #151515 !important;
}

.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
  border-color: #bbbbbb !important;
}

  .partners-section img {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      height: 100px;
      object-fit: contain;
  }
  .slider-images {
    display: flex;
      justify-content: space-between;
  }


  /* old slider image css  */
  /* .partners-section img {
    display: inline-block;
    vertical-align: middle;
    max-height: 58px;
  } */
.ant-select-selection-selected-value,
.ant-select-selection__placeholder {
}

.ant-select-selection {
  /* border: none; */
  border-radius: 0 !important;
}

.ant-form-explain {
  /* display: none; */
  font-size: 10px;
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-input {
  border-radius: 0px;
  background-color: transparent;
  /* border: none; */
  color: #bbbbbb;
}

.ant-input-group-addon {
  border-radius: 0px;
  background-color: transparent;
  border: none;
  color: #bbbbbb;
}

.item-link {
  text-decoration: none;
  padding: 0 20px;
}

.item-link:hover {
  color: #ffd500 !important;
}

.item-link:hover {
  color: #ffd500 !important;
}

.custom-label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-label .required-mark {
  color: #fcbe00;
  font-weight: 400;
}

.custom-input,
.custom-input .ant-select-selection {
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7 !important;
  color: #7f7f7f !important;
}

.ant-input {
  height: 34px !important;
}

hr {
  border: 0;
  height: 1px;
  background: #3333331a;
  margin-bottom: 20px;
  margin-top: 20px;
}

hr {
  border: 0;
  height: 1px;
  background: #3333331a;
  margin-bottom: 20px;
  margin-top: 20px;
}

.paddingLR5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.paddingLR15 {
  padding-left: 15px;
  padding-right: 15px;
}

.upload-button-link {
  position: absolute !important;
  right: 35px !important;
  top: 30px;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #fcbe00 !important;
  font-size: 12px !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ffd500 !important;
  border-color: #ffd500 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ffd500 !important;
}

.ant-checkbox-checked:after {
  border: 1px solid #fcbe00 !important;
}

.ant-tabs-nav {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #7f7f7f;
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #e4b919 !important;
}

.ant-tabs-ink-bar {
  background-color: #e4b919 !important;
}

.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: left !important;
}

.ant-collapse {
  border: none !important;
  background-color: #fff !important;
}

.ant-collapse > .ant-collapse-item {
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #151515;
}

.ant-divider-vertical {
  height: 1.4em !important;
}

.slide-pane__overlay {
  z-index: 15;
}

.slide-pane__header {
  display: none;
}

.slide-pane__content {
  padding: 0px;
  background-color: #f5f5f5;
}

.owl-carousel .owl-item img {
  display: block;
  width: auto !important;
}

.owl-prev {
  position: absolute;
  top: 30%;
  left: 0;
  font-size: 30px;
  color: #acacac !important;
}

.owl-next {
  position: absolute;
  top: 30%;
  right: 0;
  font-size: 30px;
  color: #acacac !important;
}

.ant-notification {
    z-index: 10000;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-25 {
  margin-bottom: 25px;
}

.padding20 {
  padding: 20px;
}
.paddingLR10 {
  padding: 0 10px;
}

.marginTB20 {
  margin: 20px 0;
}

.marginB20 {
  margin-bottom: 20px;
}

.custom-search-menu.ant-dropdown-menu {
  margin-top: 6.4px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 300px;
  overflow: auto;
}
.custom-search-menu .ant-dropdown-menu-item:hover,
.custom-search-menu .ant-dropdown-menu-submenu-title:hover,
.custom-search-menu
  .ant-dropdown-menu-submenu-title:visited
  .custom-search-menu
  .ant-dropdown-menu-submenu-title:active {
  background-color: transparent;
  background-image: linear-gradient(to bottom, #fecc00, #f1b300);
}
.custom-search-menu .ant-dropdown-menu-item:first-child,
.custom-search-menu .ant-dropdown-menu-submenu-title:first-child,
.custom-search-menu .ant-dropdown-menu-item:first-child > a,
.custom-search-menu .ant-dropdown-menu-submenu-title:first-child > a {
  border-radius: 0;
}
.custom-search-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 10px 15px;
}
.custom-search-menu .ant-dropdown-menu-item img {
  width: 20px;
  vertical-align: middle;
  float: right;
}

.custom-modal-filter {
  z-index: 1055 !important;
}

.custom-modal-filter.ant-drawer-bottom.ant-drawer-open
  .ant-drawer-content-wrapper {
  height: 70vh !important;
  width: calc(100% - 2px);
  margin-left: 1px;
}
.custom-modal-filter .ant-drawer-content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-modal-filter .ant-drawer-body {
  padding: 15px;
}
.custom-modal-filter .reset-button {
  float: right;
  font-size: 10px;
  color: #0080c8;
}
.custom-modal-filter hr.yellow-divider {
  width: 100%;
  background: #fecc00;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.custom-modal-filter .ant-btn.btn-lg {
  border-radius: 100px;
  border: none;
  height: 50px;
  width: 100%;
}
.custom-modal-filter .apply-button {
  background-image: linear-gradient(92deg, #fecc00 0%, #f1b300 97%);
}
.cancel-button {
  border-radius: 100px;
  background-color: #d5d5d5;
}
.custom-close-option .close-bar {
  width: 20px;
  height: 4px;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  display: inline-block;
  margin-top: -25px;
  margin-bottom: 10px;
}
.custom-model-drawer2 .ant-drawer-body {
  padding: 15px 24px;
}
.custom-model-drawer2 hr.yellow-divider {
  width: 100%;
  background: #fecc00;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 12px;
}
.custom-model-drawer2 .social-media-icon img {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .promo-modal .ant-modal-body {
    padding: 80px 20px !important;
  }

  .promo-modal-new .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/feb_background_mobile.png") !important;
  }

  .promo-modal-new h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-garasi h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-garasi p {
    margin-bottom: 5px;
    text-align: left;
    font-size: 15px !important;
  }

  .promo-modal-santa h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-santa p {
    margin-bottom: 5px;
    text-align: left;
    font-size: 15px !important;
  }

  .promo-modal-garasi .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/background-popup-garasi-mobile.jpg") !important;
  }

  .promo-modal-santa .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/santa_coupon_bg_mobile.jpg") !important;
  }

  .promo-modal-travel-new .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/popup-background-travel-mobile.jpg") !important;
  }

  .promo-modal-tcash .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/popup-background-tcash-mobile.jpg") !important;
  }

  .promo-modal-tcash h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-travel-new h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .ant-input-group-addon {
    border-radius: 0px;
    background-color: transparent;
    border: 1px solid #d9d9d9;
    color: #bbbbbb;
  }

  .text-center-responsive {
    text-align: center !important;
  }

  .linear-wipe {
    margin-top: 60px;
    font-size: 20px;
    color: #000;
    padding: 20px;
    width: 100% !important;
  }

  .vehicle {
    background: url("../image/vehicle-mobile1.png") rgb(245, 245, 245) !important;
    background-size: 100% 60% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(92vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .travel {
    background: url("../image/travel-mobile.png") rgb(245, 245, 245) !important;
    background-size: 100% 60% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(92vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .vehicle-hero {
    background: url("../image/vehicle-mobile1.png") rgb(245, 245, 245) !important;
    background-size: 100% 50% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(140vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-repeat: no-repeat !important;
    padding-top: 20px;
  }

  .vehicle-home {
    background: url("../image/home_header.png") rgb(245, 245, 245) !important;
    background-size: 100% 40% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(75vh - 30px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .health-hero {
    background: url("../image/health_image.png") rgb(245, 245, 245) !important;
    background-size: 100% 100% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(130vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-repeat: no-repeat !important;
    padding-top: 20px;
  }

  .travel-hero {
    background: url("../image/travel.png") rgb(245, 245, 245) !important;
    background-size: 100% 100% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(130vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-repeat: no-repeat !important;
    padding-top: 20px;
  }

  img.mastercard {
    margin-top: 10px;
    align-items: center;
    height: 30px !important;
    padding-right: 0px !important;
  }

  img.visa {
    /* style={{marginTop: '10px',
            alignItems: 'center', maxWidth: '70px',
            height:'28px',width: '100%',
            display: 'block'}} */

    margin-top: 10px;
    align-items: center;
    height: 20px !important;
  }
}

.card-container {
  width: 70%;
}

@media (max-width: 1000px) {
  .card-container {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .card-container {
    width: 85%;
  }

  .img.mastercard {
    /* marginTop: '10px',
        alignItems: 'center',
        maxWidth: '70px',
        height:'40px',
        width: '100%',
        display: 'block',
        paddingRight: '16px' */
    margin-top: 10px;
    align-items: center;
    height: 40px;
    padding-right: 10px;
  }
}

.cover-list h1,
.claim-section h1 {
  font-family: Muli;
  font-size: 36px;
  font-weight: bold;
  color: #151515;
}

.cover-list h2 {
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  color: #151515;
}

.select-memebers {
  padding-left: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-icon {
  padding: 0px !important;
  height: 18px !important;
  line-height: 17px;
  margin: 0 5px;
}

.h-40 {
  height: 40px;
}

.lh-40 {
  line-height: 40px;
}

.bg-white {
  background: #ffffff;
}

.font-black {
  color: #000000 !important;
}

.ant-confirm-btns .ant-btn-primary {
  background-color: #ffd500 !important;
  color: #151515 !important;
  border-color: #ffd500 !important;
}

.declaration-confirm-modal .ant-confirm-body {
  text-align: center;
}

.declaration-confirm-modal .ant-confirm-body > .anticon {
  float: none;
  margin-bottom: 15px;
}

.declaration-confirm-modal .ant-confirm-content {
  margin-top: 0px !important;
  margin-left: 0px !important;
  text-align: justify;
}

.declaration-checkbox .ant-checkbox {
  position: absolute !important;
  top: 3px;
}

.declaration-checkbox .ant-checkbox + span {
  display: inline-block;
  padding-left: 25px;
}

.ant-message-notice-content {
  border-radius: 0px;
  font-family: "Muli", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
    Arial, sans-serif;
}

.custom-radio .ant-radio-inner {
  background: #d8d8d8 !important;
  border: 1px solid #d8d8d8 !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: #151515 !important;
  height: 10px !important;
  width: 10px !important;
  top: 2px;
  left: 2px;
}

.custom-radio .ant-radio-checked::after {
  border: 1px solid #fcbe00;
}

.promo-modal-travel-new .ant-modal-body {
  background: url("../image/popup-background-travel.jpg");
}

.promo-modal .ant-modal-body {
  /* background: url('../image/promo-popup.jpg') !important;
    background-size: cover;
    background-position: right bottom; */
  background: url("../image/promo-popup.jpg");
  background-size: cover !important;
  background-position: bottom;
  padding-bottom: 140px;
  /* height: 75vh; */
}

.promo-modal ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal li {
  font-size: 16px;
  margin-top: 10px !important;
  font-weight: 400 !important;
}

.promo-modal h4 {
  font-size: 17px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.promo-modal-new .ant-modal-body {
  /* background: url('../image/promo-popup.jpg') !important;
    background-size: cover;
    background-position: right bottom; */
  background: url("../image/feb_background_desktop.png");
  background-size: 100% 100% !important;
  padding: 120px;
  /* height: 75vh; */
}

.promo-modal-travel-new .ant-modal-body {
  /* background: url('../image/promo-popup.jpg') !important;
    background-size: cover;
    background-position: right bottom; */
  background: url("../image/popup-background-travel.jpg");
  background-size: 100% 100% !important;
  padding: 120px;
  /* height: 75vh; */
}

.promo-modal-tcash .ant-modal-body {
  background: url("../image/popup-background-tcash.jpg");
  background-size: 100% 100% !important;
  padding: 120px;
  /* height: 75vh; */
}

.promo-modal-mobile .ant-modal-body {
  /* background: url('../image/promo-popup.jpg') !important;
    background-size: cover;
    background-position: right bottom; */
  background: url("../image/popup-nosurvey-mobile.jpg");
  background-size: 100% 100% !important;
  padding: 80px 20px !important;
  /* height: 75vh; */
}

.promo-modal-mobile h3 {
  font-size: 20px !important;
  margin-bottom: 5px !important;
}

.promo-modal-tcash h3 {
  font-size: 28px;
}

.promo-modal-new ol {
  padding-left: 15px;
  list-style: initial !important;
}

strong,
b {
  font-weight: 700 !important;
}

.promo-modal-new li {
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 600 !important;
}

.promo-modal-mobile ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal-mobile li {
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 300 !important;
}

.promo-modal-travel-new ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal-travel-new li {
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 600 !important;
}

.promo-modal-tcash ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal-tcash li {
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 300 !important;
}

.promo-modal li {
  font-size: 14px !important;
  margin-top: 10px !important;
  font-weight: 300 !important;
}

.promo-modal-new h3 {
  text-align: left;
  font-size: 26px;
}

.promo-modal-travel-new h3 {
  text-align: left;
  font-size: 26px;
}

.promo-modal h4 {
  font-size: 17px;
}

.promo-modal-new h4 {
  font-size: 17px;
}

.promo-modal-travel-new h4 {
  font-size: 17px;
}

.promo-modal-travel-new h4 {
  font-size: 17px;
}

.ant-select-dropdown-menu-item {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.mobile-vehicle-type .ant-select-selection-selected-value,
.mobile-travel-type .ant-select-selection-selected-value {
  position: absolute !important;
  top: 50% !important;
  left: 0 !important;
  right: 9px !important;
  line-height: 20px !important;
  height: 20px !important;
  max-width: 100% !important;
  margin-top: -10px !important;
  overflow: hidden !important;
  -o-text-overflow: ellipsis !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  text-align: left !important;
}

.date-input-container span.ant-form-item-children,
.date-input-container span.bp3-popover-wrapper,
.date-input-container span.bp3-popover-target,
.card-date-input-container span.ant-form-item-children,
.card-date-input-container span.bp3-popover-wrapper,
.card-date-input-container span.bp3-popover-target,
.custom-date-input span.ant-form-item-children,
.custom-date-input span.bp3-popover-wrapper,
.custom-date-input span.bp3-popover-target {
  display: inline-block;
  width: 100%;
  z-index: 2 !important;
}

.bp3-transition-container {
  z-index: 30 !important;
}

.bp3-input:disabled {
  color: rgba(92, 112, 128, 1);
}

.date-input-container input {
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  color: #7f7f7f !important;
  border-radius: 0px;
  height: 32px;
  font-family: "Muli", sans-serif !important;
}

.card-date-input-container input,
.custom-date-input input {
  /* font-size: 20px;
    font-weight: 300; */
  /* padding-left: 0px; */
  border-radius: 0px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  background: transparent;
  font-family: "Muli", sans-serif !important;
}

.bp3-datepicker {
  font-size: 14px !important;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
  background: #ffd500;
  color: black;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
  background: #ffd500;
  color: black;
}

.bp3-datepicker .bp3-datepicker-month-select {
  min-width: 90px;
}

img.mastercard {
  /* marginTop: '10px',
    alignItems: 'center',
    maxWidth: '70px',
    height:'40px',
    width: '100%',
    display: 'block',
    paddingRight: '16px' */
  margin-top: 10px;
  align-items: center;
  height: 40px;
  padding-right: 10px;
}

img.visa {
  /* style={{marginTop: '10px',
        alignItems: 'center', maxWidth: '70px',
        height:'28px',width: '100%',
        display: 'block'}} */

  margin-top: 10px;
  align-items: center;
  height: 28px;
}

img.t-cash {
  /* style={{marginTop: '10px',
        alignItems: 'center', maxWidth: '100px',
        height:'40px', width: '100%',
        display: 'block', paddingRight: '16px'}} */

  margin-top: 10px;
  height: 40px;
}

.flash-image {
  position: absolute !important;
  right: -15px !important;
  margin-top: -50px !important;
  height: 70px !important;
}

.flash-image-garasi {
  height: 30px;
  position: absolute !important;
  bottom: 10px;
  margin-left: 10px;
}

.flash-image-mobile {
  position: absolute !important;
  right: -15px !important;
  height: 60px !important;
  margin-top: -45px !important;
}

.promo-modal-garasi .ant-modal-body {
  background: url("../image/background-popup-garasi.jpg");
  background-size: 100% 100% !important;
  padding: 140px;
  /* height: 75vh; */
}

.promo-modal-santa .ant-modal-body {
  background: url("../image/santa_coupon_bg_desktop.jpg");
  background-size: 100% 100% !important;
  padding: 140px;
  /* height: 75vh; */
}

.promo-modal-santa ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal-santa li {
  font-size: 16px;
  margin-top: 10px !important;
  font-weight: 400 !important;
}

.promo-modal-santa h4 {
  font-size: 17px;
}

.promo-modal-santa h3 {
  margin-bottom: 5px;
  text-align: left;
  font-size: 26px;
}

.promo-modal-santa p {
  margin-bottom: 5px;
  text-align: left;
  font-size: 26px;
}

.promo-modal-garasi ol {
  padding-left: 15px;
  list-style: initial !important;
}

.promo-modal-garasi li {
  font-size: 16px;
  margin-top: 10px !important;
  font-weight: 400 !important;
}

.promo-modal-garasi h4 {
  font-size: 17px;
}

.promo-modal-garasi h3 {
  margin-bottom: 5px;
  text-align: left;
  font-size: 26px;
}

.promo-modal-garasi p {
  margin-bottom: 5px;
  text-align: left;
  font-size: 26px;
}

.slick-slide .content-container .profile-container {
  -ms-box-orient: horizontal !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.coupon-button {
  font-size: 16px;
  background-color: #ffb500;
  color: #fff;
  border-color: #ffb500;
  margin-top: 40px;
  padding-top: 15px;
  padding-bottom: 30px;
  font-weight: 700;
  box-shadow: 1px 2px #403e3e4a;
  border-radius: 8px;
}

.coupon-button:hover {
  background-color: #ffb500;
  border-color: #ffb500;
  color: #fff;
}

.coupon-button:after {
  background-color: #ffb500;
  border-color: #ffb500;
  color: #fff;
}

.coupon-button:focus {
  background-color: #ffb500;
  border-color: #ffb500;
  color: #fff;
}

.modal-disclaimer .ant-modal-title {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.modal-disclaimer .ant-modal-body {
  padding-left: 40px;
  padding-right: 40px;
}

.modal-disclaimer .ant-modal-body li {
  list-style: disc;
  margin-left: 15px;
}

.custom-input-tia::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey;
  opacity: 1;
  /* Firefox */
}

.custom-input-tia:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey;
}

.custom-input-tia::-ms-input-placeholder {
  /* Microsoft Edge */
  color: grey;
}

/* promo coupon main website modal */
.promoCouponWebsiteModel {
  text-align: center;
  z-index: 10 !important;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}

.promoCouponWebsiteModel:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.promoCouponWebsiteModel .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

/* promo coupon main website modal close button design*/
.promoCouponWebsiteModel .ant-modal-close {
  background-image: linear-gradient(to right, #006eb9 0%, #15205e 100%);
  color: #fff;
  border: solid 5px #707070;
  border-radius: 50%;
  right: -10px;
  top: -10px;
}

.promoCouponWebsiteModel .ant-modal-close-x {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.promoCouponWebsiteModel .ant-modal-footer {
  background: transparent;
  border: none;
  text-align: center;
  padding: 0;
  position: relative;
}

.promoCouponWebsiteModel
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  position: absolute;
}

.promoCouponWebsiteModel .ant-modal-footer .ant-btn-primary {
  border-radius: 25px;
  background-image: linear-gradient(to right, #006eb9 0%, #15205e 100%);
  position: absolute;
  left: calc(50% - 65px);
  top: -15px;
}

.promoCouponWebsiteModel ol {
  list-style-type: decimal;
  padding: 20px;
}

.promoCouponWebsiteModel .modelAvtar {
  position: absolute;
  left: -180px;
  width: 200px;
  top: 0;
}

.promoCouponWebsiteModel .modelBorder {
  position: absolute;
  width: 116%;
  height: 120%;
  margin-top: -10%;
  margin-left: -10%;
  z-index: -1;
}

@media (min-width: 1200px) {
  .card-select.no-border .ant-select-selection,
  .ant-calendar-picker.no-border .ant-calendar-picker-input,
  .ant-input.no-border,
  .card-date-input-container .no-border input {
    border: none !important;
  }

  .cph-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .cp-16 {
    padding: 16px;
  }

  .bl {
    border-left: 1px solid #e5e5e5;
  }

  .br {
    border-right: 1px solid #e5e5e5;
  }

  .card-select,
  .card-date-picker,
  .card-date-input-container input {
    font-size: 20px;
    font-weight: 300;
    padding-left: 0px !important;
  }

  .health-date-picker .ant-calendar-picker-input {
    font-size: 20px !important;
    font-weight: 300 !important;
    color: #000000 !important;
    padding-left: 0px;
  }

  .img.mastercard {
    /* marginTop: '10px',
        alignItems: 'center',
        maxWidth: '70px',
        height:'40px',
        width: '100%',
        display: 'block',
        paddingRight: '16px' */
    margin-top: 10px;
    align-items: center;
    height: 40px;
    padding-right: 10px;
  }

  .hide-gt-md {
    display: none;
  }

  .mt-80-gt-md {
    margin-top: 80px;
  }

  .text-right-gt-md {
    text-align: right !important;
  }

  .no-border-gt-md {
    border: none !important;
  }

  .mb-30-gt-md {
    margin-bottom: 30px;
  }

  .ph-50-gt-md {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .card-container {
    width: 85%;
  }

  .img.mastercard {
    /* marginTop: '10px',
        alignItems: 'center',
        maxWidth: '70px',
        height:'40px',
        width: '100%',
        display: 'block',
        paddingRight: '16px' */
    margin-top: 10px;
    align-items: center;
    height: 40px;
    padding-right: 10px;
  }
}

@media (max-width: 1000px) {
  .card-container {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .hide-lt-md {
    display: none !important;
  }

  .full-width-lt-md {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .partners-section img {
    max-width: 80%;
    width: 50px;
    height: 50px;
  }

  /* old slider image css  */
  /* .partners-section img {
    max-width: 80%;
  } */
  .promo-modal .ant-modal-body {
    padding: 80px 20px !important;
  }

  .promo-modal-new .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/feb_background_mobile.png") !important;
  }

  .promo-modal-new h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-garasi h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-garasi p {
    margin-bottom: 5px;
    text-align: left;
    font-size: 15px !important;
  }

  .promo-modal-santa h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-santa p {
    margin-bottom: 5px;
    text-align: left;
    font-size: 15px !important;
  }

  .promo-modal-garasi .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/background-popup-garasi-mobile.jpg") !important;
  }

  .promo-modal-santa .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/santa_coupon_bg_mobile.jpg") !important;
  }

  .promo-modal-travel-new .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/popup-background-travel-mobile.jpg") !important;
  }

  .promo-modal-tcash .ant-modal-body {
    padding: 80px 20px !important;
    background: url("../image/popup-background-tcash-mobile.jpg") !important;
  }

  .promo-modal-tcash h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .promo-modal-travel-new h3 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }

  .ant-input-group-addon {
    border-radius: 0px;
    background-color: transparent;
    border: 1px solid #d9d9d9;
    color: #bbbbbb;
  }

  .text-center-responsive {
    text-align: center !important;
  }

  .linear-wipe {
    margin-top: 60px;
    font-size: 20px;
    color: #000;
    padding: 20px;
    width: 100% !important;
  }

  .vehicle {
    background: url("../image/vehicle-mobile1.png") rgb(245, 245, 245) !important;
    background-size: 100% 60% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(92vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .travel {
    background: url("../image/travel-mobile.png") rgb(245, 245, 245) !important;
    background-size: 100% 60% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(92vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .vehicle-hero {
    background: url("../image/vehicle-mobile1.png") rgb(245, 245, 245) !important;
    background-size: 100% 50% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(140vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-repeat: no-repeat !important;
    padding-top: 20px;
  }

  .vehicle-home {
    background: url("../image/home_header.png") rgb(245, 245, 245) !important;
    background-size: 100% 40% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(75vh - 30px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 20px !important;
    background-repeat: no-repeat !important;
  }

  .health-hero {
    background: url("../image/health_image.png") rgb(245, 245, 245) !important;
    background-size: 100% 100% !important;
    background-position: right bottom !important;
    width: 100vw !important;
    min-height: calc(130vh - 50px) !important;
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-repeat: no-repeat !important;
    padding-top: 20px;
  }

  .travel-hero {
    background-image: url("https://storage.googleapis.com/pp_static_staging/banner_travel_partner_mobile.png") !important;
    min-height: 80vh;
    align-items: start;
    background-size: cover;
  }

  img.mastercard {
    margin-top: 10px;
    align-items: center;
    height: 30px !important;
    padding-right: 0px !important;
  }

  img.visa {
    /* style={{marginTop: '10px',
            alignItems: 'center', maxWidth: '70px',
            height:'28px',width: '100%',
            display: 'block'}} */

    margin-top: 10px;
    align-items: center;
    height: 20px !important;
  }

  .promoCouponWebsiteModel .modelAvtar {
    display: none;
  }
}

@media (max-width: 500px) {
  .promoCouponWebsiteModel .modelBorder {
    height: 100%;
    width: 100%;
    transform: rotate(20deg);
  }
  .marginTB20 {
    margin: 10px 0;
  }
  
  .marginB20 {
    margin-bottom: 10px;
  }
}
